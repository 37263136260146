import React, {Component} from 'react';

const buttonArrayStyle = {
	maxWidth: "800px",
	margin: "auto",
	padding: "2em, 1em",
	backgroundColor: "white"
};

const instructionStyle = {
	marginLeft: "0.5em",
	padding: "0",
	textAlign: "center",
    fontSize: "18px"
};

class Instruction extends Component {
	render() {
		return (
			<div style={buttonArrayStyle}>
				<h2 style={instructionStyle}>{this.props.text} {this.props.previousLink ?
					<a style={{textDecoration: "underline"}} href={"/" + this.props.previousLink}> or go to previous
						selection</a> : ""}</h2>
			</div>
		);
	}
}

export default Instruction;