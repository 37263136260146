import React, {Component} from 'react';
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";

const buttonArrayStyle = {
	maxWidth: "800px",
	margin: "auto",
	paddingBottom: "1em",
	paddingTop: "1.5em",
	backgroundColor: "white",
};


class FullWidthButtonArray extends Component {
	render() {
		return (
			<div style={buttonArrayStyle}>
				{this.props.items.map((item, index) => {
					return <Button className="red-hover" style={{width: "98%", margin: "1%", fontSize: "22px", backgroundColor: item.color}}
					               color={item.color} key={index} href={item.url}
					               primary>{this.props.prefix + item.name + " " + item.counter}</Button>
				})}
			</div>
		);
	}
}

export default FullWidthButtonArray;