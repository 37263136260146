import React, {Component} from 'react';
import Step from "semantic-ui-react/dist/commonjs/elements/Step/Step";

const navStyle = {
	maxWidth: "800px",
	margin: "auto",
	padding: "1.5em",
	backgroundColor: "white"
};

const navComponentStyle = {
	margin: "auto",
};

class NavigationElement extends Component {
	render() {
		return (
			<div style={navStyle}>
                <Step.Group widths={3} unstackable items={this.props.items} style={navComponentStyle}/>
			</div>
		);
	}
}

export default NavigationElement;