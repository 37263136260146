import React, {Component} from "react";

class Footer extends Component {
	render() {
		return (
			<div className="footer">

			</div>
		);
	}
}

export default Footer;
